
import "./src/assets/style.css"
// import "./src/assets/style-rtl.css"
import "./src/assets/print.css"
import "./src/assets/style-theme.css"

export const onRouteUpdate = () => {
    var fusetag = fusetag || {
        que: [],
    };

    [...document.querySelectorAll('[data-fuse]')]
        .forEach(fuseId => {
            fusetag.que.push(() => {
                fusetag.loadSlotById(fuseId)
            });
        });
}
